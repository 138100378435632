import React from 'react'
import Layout from '../components/common/Layout'


const Terms = () => {
    return (
        <Layout>
            
        </Layout>
    )
}

export default Terms
